@font-face {
  font-family: 'MaisonNeueWEB';
  src: url(./assets/fonts/MaisonNeueWEB/MaisonNeueWEB-Medium.woff);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  background: linear-gradient(to bottom, #00294F, #0A1015);
  position: relative;
}
body {
  margin: 0;
  font-family: 'MaisonNeueWEB',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.rc-tooltip {
  background-color: rgb(0 0 0 / 70%) !important;
  max-width: 300px;
}
.rc-tooltip-placement-bottom 
.rc-tooltip-arrow-inner, 
.rc-tooltip-placement-bottomLeft 
.rc-tooltip-arrow-inner, 
.rc-tooltip-placement-bottomRight 
.rc-tooltip-arrow-inner {
  border-bottom-color: rgb(0 0 0 / 70%) !important;
}
.rc-tooltip-arrow {
  border-bottom-color: rgb(0 0 0 / 70%) !important;
}
.rc-tooltip-inner {
  color: #fff !important;
  background-color: transparent !important;
  border: none !important;
}